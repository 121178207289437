import './App.css';
import React, { useEffect, useState } from 'react';
// import { getDatabase, off, ref, onValue } from "firebase/database";

function App() {
  const [data, setData] = useState(null);
  const [sn, setSn] = useState(null);
  const [dateData, setdateData] = useState(null);

  useEffect(() => {
    const url = window.location.href;
    console.log("url", url);

    const paramStartIndex = url.indexOf('d=');
    if (paramStartIndex === -1) {
      console.log("No 'd=' parameter found in the URL.");
    } else {
      const param = url.slice(paramStartIndex + 2);
      console.log("param :", param);

      if (param.length >= 14) {
        const hexString = param.substring(0, 14);
        const decimalValue = parseInt(hexString, 16);
        console.log(decimalValue);

        const snValue = decimalValue.toString();
        setSn(snValue);
        console.log("sn", snValue);
      } else {
        console.log("Parameter is shorter than expected.");
      }
    }
  }, []);

  // useEffect(() => {
  //   console.log('useEffect called with sn:', sn);

  //   if (sn) {
  //     const db = getDatabase();
  //     const deviceRef = ref(db, `forms/${sn}`);
  //     const handleValueChange = (snapshot) => {
  //       const value = snapshot.val();
  //       setData(value);
  //       console.log(value.Temperaturetest);
  //     };

  //     onValue(deviceRef, handleValueChange);
  //     onValue(deviceRef, handleValueChange, error => {
  //       console.error("Firebase read failed:", error);
  //     });
  //     return () => {
  //       off(deviceRef, handleValueChange);
  //     };
  //   }
  // }, [sn]);

  useEffect(() => {
    if (sn) {
      const fetchData = () => {
        const url = `https://nfcresult-60866-default-rtdb.asia-southeast1.firebasedatabase.app/zld_110a_forms/${sn}.json`;

        fetch(url)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            setData(data);
            console.log("https://nfcresult-60866-default", data);
          })
          .catch(error => {
            console.error('There was a problem with your fetch operation:', error);
          });
      }
      fetchData();
    }

  }, [sn]);

  useEffect(() => {


    if (data) {
      const fetchData = () => {
        const url = `https://nfcresult-60866-default-rtdb.asia-southeast1.firebasedatabase.app/zld_110a_prod/${data.batchNo ? data.batchNo : "0001"}.json`;
        fetch(url)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            setdateData(data);
            console.log("https://nfcresult-60866-default", data);
          })
          .catch(error => {
            console.error('There was a problem with your fetch operation:', error);
          });
      }
      fetchData();
    }
  }, [data]);

  // useEffect(() => {
  //   console.log('useEffect called with sn:', sn);

  //   if (data) {
  //     const db = getDatabase();
  //     const nfcDeviceMapRef = ref(db, `nfc_slot_prod/${data.batchNo ? data.batchNo : "0001"}`);
  //     const handleValueChange = (snapshot) => {
  //       const value = snapshot.val();
  //       setdateData(value);
  //       console.log("https://nfcresult-60866-default", dateData);
  //     };

  //     onValue(nfcDeviceMapRef, handleValueChange);
  //     onValue(nfcDeviceMapRef, handleValueChange, error => {
  //       console.error("Firebase read failed:", error);
  //     });
  //     return () => {
  //       off(nfcDeviceMapRef, handleValueChange);
  //     };
  //   }
  // }, [data]);

  useEffect(() => {
    console.log("sn in separate useEffect", sn);
  }, [sn]);

  useEffect(() => {
    // console.log(data);
  }, [data]);

  useEffect(() => {
    // console.log(dateData);
  }, [dateData]);

  if (!data || !data.Temperaturetest) {
    return <div className="mx-auto flex items-center justify-center h-screen bg-white text-black-700 text-2xl font-bold font-lato">
      {data ? JSON.stringify(data) : "Loading..."}
    </div>
  }

  return (
    <div className="mx-auto max-w-[550px] bg-white text-black font-lato">
      {/* Header */}
      <header className="text-center py-8">
        <a href="https://www.currentbody.com/collections/currentbody-skin"><img className="w-[233px] h-[41px] mx-auto mb-6" alt="CurrentBody Skin Logo" src="https://www.currentbody.com/cdn/shop/t/1256/assets/currentbody-skin_1024x1024.png?v=170181410224247424011724169866" /></a>
        <h1 className="text-4xl font-bold mb-4">Veritace® Testing & Traceability</h1>
        <img className="w-[331px] h-[246px] mx-auto mb-6" alt="LED Neck&Dec" src="currentbodymask.png" />
        <p className="text-3xl font-bold mb-2">LED Neck&Dec:<br></br>Series 2</p>
        <p className="text-sm mb-4">NFC card number: {sn}</p>
      </header>

      {/* Product Traceability */}
      <section className="px-6 mb-12 bg-gray-50 py-8">
        <h2 className="text-3xl font-bold mb-8">PRODUCT TRACEABILITY</h2>
        <div className="relative pl-12 border-l-2 border-black">
          {[
            { label: 'Component sourcing', date: dateData ? dateData.cs : "Loading..." },
            { label: 'LED selection process', date: dateData ? dateData.lsp : "Loading..." },
            { label: 'Component transfer to manufacturing', date: dateData ? dateData.cttm : "Loading..." },
            { label: 'Product build', date: dateData ? dateData.pd : "Loading..." },
            { label: 'Final product quality testing', date: dateData ? dateData.fpqt : "Loading..." },
            { label: 'Ready for global distribution', date: dateData ? dateData.rfgd : "Loading..." },
          ].map((item, index) => (
            <div key={index} className="mb-8 relative">
              <div className="absolute -left-[21px] top-1 w-6 h-6 bg-black rounded-full"></div>
              <div className="ml-4">
                <p className="text-lg font-bold">{item.label}</p>
                <p className="text-base">{item.date}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Spectroradiometer Precision Testing */}
      <section className="px-6 mb-12">
        <h2 className="text-3xl font-bold mb-8">SPECTRORADIOMETER PRECISION TESTING</h2>
        {[
          { name: 'Red 633nm', src: data.w633 },
          { name: 'Near-Infrared 830nm', src: data.w830 },
          { name: 'Deep Near-Infrared 1072nm', src: data.w1072 }
        ].map((wavelength, index) => (
          <div key={index} className="mb-8">
            <h3 className="text-2xl mb-2">{wavelength.name}</h3>
            <p className="text-green-600 text-xl font-bold mb-2">PASSED √</p>
            <img
              className="w-full h-auto"
              src={wavelength.src}
              alt={`${wavelength.name} graph`}
            />
          </div>
        ))}
      </section>

      {/* Testing Results */}
      <section className="px-6 mb-12 bg-gray-50 py-8">
        <h2 className="text-3xl font-bold mb-8">TESTING RESULTS</h2>
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-black text-white">
              <th className="p-3 text-left text-lg">Test</th>
              <th className="p-3 text-left text-lg">Status</th>
              <th className="p-3 text-left text-lg">Equipment</th>
            </tr>
          </thead>
          <tbody>
            {[
              { test: 'LED placement check', equipment: 'Imager' },
              { test: 'Device power output', equipment: 'Multimeter' },
              { test: 'Battery capacity', equipment: 'Battery capacity division system' },
              { test: 'Temperature', equipment: 'Humidity temperature meter' },
              { test: 'Standard and Electrical Safety', equipment: 'Third party laboratory' },
            ].map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}>
                <td className="p-3 text-base">{item.test}</td>
                <td className="p-3 text-base font-bold whitespace-nowrap">
                  PASSED <span className="text-green-600 text-xl">√</span>
                </td>
                <td className="p-3 text-base">{item.equipment}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      {/* Product Information */}
      <section className="px-6 mb-12">
        <h2 className="text-3xl font-bold mb-8">CurrentBody Skin LED Neck&Dec: Series 2</h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="font-bold">Production date</div><div>{dateData ? dateData.pd : "16th August 2024"}</div>
          <div className="font-bold">Battery capacity</div><div>2600mAh</div>
          <div className="font-bold">Dimensions</div><div>190 × 98 × 207 mm</div>
          <div className="font-bold">Treatment time</div><div>10 minutes (Auto-timer)</div>
          <div className="font-bold">Treatment protocol</div><div>3-5 times weekly</div>
          <div className="font-bold">Battery charging duration</div><div>4.5 hours</div>
          <div className="font-bold">Product operational time</div><div>10 uses per charge</div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-black text-white py-8 text-center">

        <p className="mb-2">Learn more at</p>
        <a href="https://currentbody.com/veritace" className="underline text-xl">currentbody.com/veritace</a>
      </footer>
    </div>
  );
}

export default App;
