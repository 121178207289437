import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyAmuB8HQWCuY_FRrBwG5y-M-PZpKdo0aHo",
//     authDomain: "nfcresult-60866.firebaseapp.com",
//     databaseURL: "https://nfcresult-60866-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "nfcresult-60866",
//     storageBucket: "nfcresult-60866.appspot.com",
//     messagingSenderId: "314134856705",
//     appId: "1:314134856705:web:808e0dfbb2914d77d0ec00"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
